import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

const fireStore = {
  apiKey: 'AIzaSyBe_VapGFrtvZKVHcqA_o4eFvH6jy-sHdU',
  authDomain: 'amexvenn.firebaseapp.com',
  databaseURL: 'https://amexvenn.firebaseio.com',
  projectId: 'amexvenn',
  storageBucket: 'amexvenn.appspot.com',
  messagingSenderId: '147533181542',
  measurementId: 'G-56NT0GK1NT',
  appId: '1:147533181542:web:856871d777b72da811b3a7',
};

var fireStoreDev = {
  apiKey: 'AIzaSyCMDX8-GmW7cOhy4_EFsBXPbmNHMLUfU0w',
  authDomain: 'amexvenn-dev.firebaseapp.com',
  projectId: 'amexvenn-dev',
  storageBucket: 'amexvenn-dev.appspot.com',
  messagingSenderId: '893701248414',
  appId: '1:893701248414:web:97a0d563b6fd9b7365f28f',
};

firebase.initializeApp(
  process.env.NODE_ENV === 'development' ? fireStoreDev : fireStore
);

export default firebase;
