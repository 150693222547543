import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useFindStoresByCategoryName, UseGetCategories } from '../../hooks';

export const Category = () => {
  const { categoryName }: { categoryName: string } = useParams();
  const categories = UseGetCategories();
  const { data } = useFindStoresByCategoryName(categoryName);

  const findCategories = useMemo(
    () => (storeCategories: string[]) => {
      const matchingCategories = storeCategories.map((storecategory) =>
        categories?.find(({ id }) => id === storecategory)
      );
      return matchingCategories
        ?.filter((item) => item)
        ?.map((item, i) => {
          return (
            <p
              key={item?.name}
              className={`px-2 mr-1 inline-flex text-xs leading-5 font-semibold rounded-full ${item?.color}`}
            >
              <Link to={`/category/${item?.name}`}>{item?.name}</Link>
            </p>
          );
        });
    },

    [categories]
  );
  return (
    <div className="container px-4 py-2 mx-auto bg-white dark:bg-gray-800">
      <div className="items-start lg:flex">
        <div className="w-full lg:w-1/2">
          <div className="lg:max-w-lg">
            <h1 className="text-1xl font-semibold text-gray-800 uppercase dark:text-white lg:text-3xl">
              Kategori: {categoryName}
            </h1>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              Her er en oversikt over brukersteder som har kategorien{' '}
              {categoryName} knyttet til seg. Ser du noen steder som mangler er
              det bare å legge det til med linken under
            </p>

            <p className="p-2 mt-4 w-full text-center text-s leading-5 font-semibold rounded-full bg-blue-200 text-blue-800">
              <Link to="/new">Legg til nytt brukersted</Link>
            </p>
          </div>
        </div>
        <ul className="divide-y divide-gray-200">
          {data
            ?.sort((a, b) => (a.name < b.name ? -1 : 1))
            ?.filter((item) => !item.needsValidation)
            ?.map((store) => {
              return (
                <li key={store.name}>
                  <div className="px-2 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <p className="text-md text-gray-700 dark:text-white md:truncate">
                        <Link to={`/userplace/${store.name}`}>
                          {store.name}
                        </Link>
                      </p>
                      <div className="ml-2 flex-shrink-0 flex flex-col  ">
                        <p className="flex items-center text-md font-light text-gray-500 dark:text-gray-300">
                          {store?.createdAt?.toDate().toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="sm:flex">
                        {findCategories(store.categories)}
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};
