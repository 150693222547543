import React, { useMemo } from 'react';
import firebase from '../../firebaseconfig';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router';
import { useFindStoreByName, UseGetCategories } from '../../hooks';

import { Link } from 'react-router-dom';

export const StorePage = () => {
  const { storeName }: { storeName: string } = useParams();
  const { data, loading, error } = useFindStoreByName(storeName);
  const categories = UseGetCategories();

  const findCategories = useMemo(
    () => (storeCategories: string[]) => {
      const matchingCategories = storeCategories.map((storecategory) =>
        categories?.find(({ id }) => id === storecategory)
      );
      return matchingCategories
        ?.filter((item) => item)
        ?.map((item, i) => {
          return (
            <p
              key={item?.name}
              className={`px-2 mr-1 inline-flex text-xs leading-5 font-semibold rounded-full ${item?.color}`}
            >
              <Link to={`/category/${item?.name}`}>{item?.name}</Link>
            </p>
          );
        });
    },

    [categories]
  );

  firebase.analytics().logEvent(`view_store_${storeName}`);
  if (loading) return <div>Laster..</div>;
  if (error) return <div>Feil!</div>;

  const store = data[0];

  const categoriesName = store?.categories
    ?.map((storecategory) => categories?.find(({ id }) => id === storecategory))
    ?.filter((item) => item);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bruke amex hos {storeName}</title>
        <meta
          name="keywords"
          content={`amex, virker, bruke, tar ${storeName}, ${categoriesName?.map(
            (item) => item?.name
          )}`}
        />
      </Helmet>

      <section className="max-w-2xl px-2 sm:px-2 mx-auto my-2 bg-white rounded-lg shadow-lg dark:bg-gray-800">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-700 dark:text-white">
            <a
              target="_blank"
              className="text-blue-600 dark:text-blue-400 hover:underline"
              rel="noopener noreferrer"
              href={store.urls?.affiliate || store.urls.url}
            >
              {storeName} <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
          </h1>
          <span className="text-sm font-light text-gray-600 dark:text-gray-400">
            {store?.createdAt?.toDate().toLocaleDateString()}
          </span>
        </div>

        <div className="mt-4">
          {store?.info && (
            <span className="px-3 py-1 text-sm font-bold text-gray-100 bg-gray-600 rounded ">
              {(store?.info?.length && store?.info?.join(', ')) || store.info}
            </span>
          )}

          <p className="mt-4 text-gray-600 dark:text-gray-300">
            Det skal være mulig å bruke American Express (amex) hos {storeName}.{' '}
            <br />
            {store.paypal &&
              ' Men du må koble kortet ditt til en PayPal konto for å kunne betale.'}
          </p>

          {store?.purchase_reward_rate && (
            <p className="mt-4 text-gray-600 dark:text-gray-300">
              Eurobonus{' '}
              <a href="https://eurobonus.shopping/nb/how">opptjening</a> per
              100kr: {store?.purchase_reward_rate * 100} poeng
            </p>
          )}

          {store?.description && (
            <>
              <h2 className="my-4 text-xl font-bold text-gray-500 dark:text-white">
                Beskrivelse
              </h2>
              <p className="mt-2 text-gray-600 dark:text-gray-300">
                {store?.description}
              </p>
            </>
          )}

          <div className="mt-6 flex flex-wrap">
            {findCategories(store.categories)}
          </div>
        </div>
      </section>
    </div>
  );
};
