import React from 'react';
import { Store } from '../../types';
import { Link } from 'react-router-dom';
import { UseGetStoresByCategoryId } from '../../hooks';
import { colors } from '../../utils/utils';
import Card from './Card';

const Loading = () => (
  <div className="bg-white dark:bg-gray-800 ">
    <ul className="divide-y divide-gray-200">
      {[0, 1, 2, 3, 4, 5].map((item, i) => (
        <li key={item}>
          <div className="px-2 py-4 sm:px-6">
            <div className="flex items-center justify-between animate-pulse  ">
              <p className="text-md text-gray-700 dark:text-white md:truncate">
                <Link to={'/'}>Henter data...</Link>
              </p>
              <div className="ml-2 flex-shrink-0 flex flex-col  ">
                <p className="flex items-center text-md font-light text-gray-500 dark:text-gray-300">
                  Laster
                </p>
              </div>
            </div>
            <div className="mt-2 sm:flex sm:justify-between">
              <p
                className={`px-2 mr-1 text-xs leading-5 font-semibold rounded-full ${colors[i]} w-${i}/6`}
              >
                Laster
              </p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

export const LatestStores = () => {
  const { data: stores, loading, error } = UseGetStoresByCategoryId('all');

  if (error) return <div>Feil!</div>;

  return (
    <div className="bg-white dark:bg-gray-800">
      {loading ? (
        <Loading />
      ) : (
        <ul className="divide-y divide-gray-200">
          {stores
            ?.filter((item: Store) => !item.needsValidation)
            ?.map((store) => (
              <Card key={store.name} store={store} />
            ))}
        </ul>
      )}
    </div>
  );
};
