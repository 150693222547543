import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="flex flex-col items-center justify-between px-6 py-4 bg-white dark:bg-gray-800 sm:flex-row">
    <Link
      to="/"
      className="text-xl font-bold text-gray-800 dark:text-white hover:text-gray-700 dark:hover:text-gray-300"
    >
      AmexVenn
    </Link>

    <p className="py-2 text-gray-800 dark:text-white sm:py-0">
      This website is in no way affiliated with, funded by, or associated with
      the American Express Company.
    </p>
  </footer>
);

export default Footer;
