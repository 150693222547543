import React, { useEffect, useState } from 'react';
import { Illustration } from './Illustration';
import { LinkButton } from '../../components/LinkButton';

export const Left = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsMobile(windowWidth < 768);
  }, [windowWidth]);

  return (
    <div className="">
      <h1 className="text-xl lg:text-2xl font-semibold text-gray-800 uppercase dark:text-white">
        American Express brukersteder
      </h1>

      <div className="flex justify-center items-center">
        <Illustration isMobile={isMobile} />
      </div>

      <p className="my-4 lg:my-12 text-gray-600 dark:text-gray-400">
        Det er ikke alltid like lett å vite hvor man kan betale med American
        Express (Amex). Her finner du en oversikt over steder det skal kunne gå
        an å betale med American Express (amex) kredittkort.
        <br />
        Oversikten er basert på hva brukere innrapporterer og kan være utdatert
        eller inneholde feil. <br />
        Denne siden har ikke noen tilknytting til American Express og er kun
        driftet av egen og andre sin interesse for å få mest ut av kredittkortet
        sitt.
      </p>

      <LinkButton to="new" text="Legg til nytt brukersted" />
    </div>
  );
};
