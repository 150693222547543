import React from 'react';
import { Link } from 'react-router-dom';

export const LinkButton = ({ to, text }: { to: string; text: string }) => {
  return (
    <Link
      className="        
              bg-blue-200
                dark:hover:bg-gray-700
                dark:hover:text-gray-200
                flex 
                font-semibold 
                hover:bg-blue-100 
                hover:text-blue-900 
                items-center
                justify-center 
                lg:px-20 
                px-5 
                py-2
                rounded-full 
                text-blue-800
                text-sm 
                transition duration-300 ease-in-out cursor-pointer"
      to={`/${to}`}
    >
      {text}
    </Link>
  );
};
