import React, { useState } from 'react';
import { UseSearch } from '../../hooks';

import { Store } from '../../types';
import Card from '../../components/StoreCard/Card';
import { commonFieldClasses } from '../../components/Form/TextField';

export const SearchPage = () => {
  const [search, setSearch] = useState('');

  const { data } = UseSearch(search);

  return (
    <div className="container mx-auto px-4 py-2 bg-white dark:bg-gray-800 max-w-xl md:mx-auto">
      <div className="w-full">
        <h1 className="text-2xl font-semibold text-gray-800 uppercase dark:text-white text-center md:text-left">
          Søk etter brukersted
        </h1>
        <div className="shadow flex mt-4">
          <input
            autoFocus
            onChange={(e) => setSearch(e.target.value)}
            className={commonFieldClasses}
            type="text"
            placeholder="Søk etter butikk..."
          />
        </div>
      </div>
      {search?.length >= 3 && (
        <div className="w-full mt-6">
          <h2 className="text-xl font-semibold text-gray-800 uppercase dark:text-white text-center md:text-left">
            Treff som ligner på <b> {search}</b>:
          </h2>
          <div className="bg-white dark:bg-gray-800 mt-4">
            <ul className="divide-y divide-gray-200">
              {data
                ?.filter((item: Store) => !item.needsValidation)
                ?.map((store) => (
                  <Card store={store} />
                ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};
