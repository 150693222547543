import React from 'react';
import { UseGetCategories } from '../../hooks';
import { Link } from 'react-router-dom';
import { LatestStores } from '../../components/StoreCard/LatestStores';

export const Right = () => {
  const categories = UseGetCategories();

  return (
    <div>
      <h3 className="mt-8 lg:mt-0 text-xl font-semibold text-gray-800 uppercase dark:text-white">
        Kategorier
      </h3>
      <div className="flex flex-wrap mt-4 ">
        {categories?.map((cat) => (
          <Link
            className={` 
             ${cat.color} 
                dark:hover:bg-gray-700
                dark:hover:text-gray-200
                flex 
                font-semibold 
                hover:bg-blue-100 
                hover:text-blue-900 
                items-center
                justify-center 
                m-4
                px-4
                py-2
                rounded-full 
                text-sm 
                transition duration-300 ease-in-out cursor-pointer
           `}
            key={cat.id}
            to={`/category/${cat.name}`}
          >
            {cat.name.charAt(0).toUpperCase() + cat.name.slice(1)}
          </Link>
        ))}
      </div>
      <h3 className="mt-8 lg:mt-0 text-xl font-semibold text-gray-800 uppercase dark:text-white">
        Nyeste brukersteder:
      </h3>
      <LatestStores />
    </div>
  );
};
