export const colors = [
  'bg-green-200 text-green-800',
  'bg-green-800 text-green-100',
  'bg-blue-200 text-blue-800',
  'bg-purple-200 text-purple-800',
  'bg-purple-400 text-purple-400',
  'bg-indigo-200 text-indigo-800',
  'bg-pink-200 text-pink-800',
  'bg-pink-500 text-pink-800',
  'bg-pink-900 text-pink-100',
  'bg-yellow-200 text-yellow-800',
];
