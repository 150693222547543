import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { UseGetCategories } from '../../hooks';
import { Store } from '../../types';

interface CardProps {
  store: Store;
}

const Card: React.FC<CardProps> = React.memo(({ store }) => {
  const categories = UseGetCategories();

  const findCategories = useMemo(
    () => (storeCategories: string[]) => {
      const matchingCategories = storeCategories.map((storecategory) =>
        categories?.find(({ id }) => id === storecategory)
      );
      return matchingCategories
        ?.filter(Boolean) // this filter removes any falsy values (e.g., undefined, null, false)
        ?.map((item) => {
          return (
            <p
              key={item?.name}
              className={`px-2 mr-1 inline-flex text-xs leading-5 font-semibold rounded-full ${item?.color}`}
            >
              <Link to={`/category/${item?.name}`}>{item?.name}</Link>
            </p>
          );
        });
    },
    [categories]
  );

  return (
    <li className="border-b border-gray-200 py-4">
      <div className="px-4 sm:px-6">
        <Link
          to={`/userplace/${store.name}`}
          className="text-lg sm:text-xl font-semibold text-gray-800 dark:text-white hover:text-blue-600 dark:hover:text-blue-300 transition-colors duration-200"
        >
          {store.name}
        </Link>
        <div className="mt-2 text-sm sm:text-md text-gray-600 dark:text-gray-400">
          {findCategories(store.categories)}
        </div>
      </div>
    </li>
  );
});

export default Card;
