import React from 'react';
import { Helmet } from 'react-helmet';
import { AddStore } from '../../components/AddStore/AddStore';
import firebase from '../../firebaseconfig';

export const AddStorePage = () => {
  firebase.analytics().logEvent('add_post_page');

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AmexVenn, legg til nytt brukerested</title>
      </Helmet>

      <div className="container px-4 py-2 mx-auto bg-white dark:bg-gray-800">
        <div className="items-center lg:flex">
          <div className="w-full lg:w-1/2">
            <div className="lg:max-w-lg">
              <h1 className="text-1xl font-semibold text-gray-800 uppercase dark:text-white lg:text-3xl">
                Legg til nytt brukersted
              </h1>
              <p className="mt-2 text-gray-600 dark:text-gray-400">
                Her kan du legge inn brukersteder som mangler i listen.
                <br />
                Innsendte brukersteder vil bli godkjent av en admin før du kan
                se det på nettsiden.
              </p>
            </div>
          </div>
          <AddStore />
        </div>
      </div>
    </div>
  );
};
