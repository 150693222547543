import { useCollection } from '@nandorojo/swr-firestore';
import { Store, Category } from './types';

const STORES = 'stores';
const CATEGORIES = 'categories';

const getCategoryById = (id: any) => {
  if (id === 'all')
    return {
      limit: 5,
      orderBy: ['createdAt', 'desc'] as any,
      //   where: ['createdAt', '!=', null],
    };
  return {
    where: [CATEGORIES, 'array-contains', id],
  };
};

export function UseFindStores({
  category,
  searchInput,
}: {
  category: string | 'all';
  searchInput: string | undefined;
}) {
  if (searchInput) return UseSearch(searchInput);

  return UseGetStoresByCategoryId(category);
}

export function UseGetStoresByCategoryId(categoryId: 'all' | string) {
  const { data, loading, error } = useCollection<any>(
    STORES,
    getCategoryById(categoryId)
  );
  return { data: data as Store[], loading, error };
}
export function UseGetCategories() {
  const { data: categories } = useCollection<any>(CATEGORIES, {
    orderBy: ['name', 'asc'],
  });

  return categories as Category[];
}

export function UseSearch(input: string = '') {
  const upperCased = input.charAt(0).toUpperCase() + input.slice(1);

  const end = upperCased.replace(/.$/, (c) =>
    String.fromCharCode(c.charCodeAt(0) + 1)
  );

  const { data, loading, error } = useCollection<any>(
    input.length >= 3 ? STORES : null,
    {
      limit: 10,
      where: [
        ['name', '>=', upperCased],
        ['name', '<', end],
      ],
    }
  );
  return { data: data as Store[], loading, error };
}

export function useFindStoreByName(storeName: string) {
  const { data, loading, error } = useCollection<any>(STORES, {
    where: ['name', '==', storeName],
    limit: 1,
  });

  return { data: data as Store[], loading, error };
}

export function useFindStoresByCategoryName(categoryName: string) {
  const { data: categoryNames } = useCollection<any>(CATEGORIES, {
    where: ['name', '==', categoryName],
  });

  const { data, loading, error } = useCollection<any>(STORES, {
    where: [
      'categories',
      'array-contains',
      categoryNames ? categoryNames[0]?.id : 0,
    ],
  });

  return { data: data as Store[], loading, error };
}
