import React from 'react';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';

import '../../styles/main.css';
import { Left } from './Left';
import { Right } from './Right';

export const Home = () => {
  return (
    <div className="min-h-screen flex flex-col bg-white dark:bg-gray-800">
      <Helmet>
        <meta charSet="utf-8" />
        <title>AmexVenn, nyeste brukeresteder</title>
      </Helmet>

      <div className="container px-2 py-2 mx-auto mt-1/3 sm:px-0 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="mr-4 space-y-4">
            <Left />
          </div>
          <div className="mr-4 space-y-4">
            <Right />
          </div>
        </div>
      </div>

      <div className="mt-auto">
        <Footer />
      </div>
    </div>
  );
};
