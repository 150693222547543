import React from 'react';
import { FieldRenderProps } from 'react-final-form';

type TextFieldProps = FieldRenderProps<string, HTMLElement> & {
  label: string;
  placeholder: string;
  disabled: boolean;
};

export const commonFieldClasses =
  'rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent';

const TextField: React.FC<TextFieldProps> = ({
  input,
  meta,
  label,
  placeholder,
  disabled,
}) => (
  <div className="my-4">
    <label className="mt-2 text-gray-600 dark:text-gray-400">{label}</label>
    <input
      {...input}
      disabled={disabled}
      placeholder={placeholder}
      className={`${commonFieldClasses} ${
        meta.error && meta.touched ? 'border-red-500' : ''
      }`}
    />
    {meta.error && meta.touched && (
      <span className="text-red-500">{meta.error}</span>
    )}
  </div>
);

export default TextField;
