import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useCollection } from '@nandorojo/swr-firestore';
import { UseGetCategories } from '../../hooks';
import firebase from '../../firebaseconfig';
import { Store } from '../../types';
import { SubmitError } from './SubmitError';
import { SubmitSucceed } from './SubmitSucceed';
import TextField from '../Form/TextField';

const required = (value: any) => (value ? undefined : 'Påkrevd felt');

export const AddStore = () => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user: any) => {
      if (!user?.uid) {
        setCurrentUser(null);
      }
      setCurrentUser(user?.uid || null);
    });
  }, []);

  const submit = useCollection<any>('stores');
  const categories = UseGetCategories();

  const onSubmit = async (values: {
    categories: string[];
    info: string;
    description: string;
    honey: string[] | undefined;
    name: string;
    url: string;
    createdBy: string;
  }) => {
    if (values.honey) {
      return null;
    }

    try {
      const newStore: Store = {
        needsValidation: true,
        name: values.name,
        categories: values.categories,
        urls: { url: values.url },
        createdAt: new Date(),
        info: values?.info?.split(',') || '',
        description: values.description || '',
        createdBy: values.createdBy,
      };
      await submit.add(newStore);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {!currentUser && (
        <>
          <p className="mt-2 text-gray-600 dark:text-gray-400">
            Innsendte brukersteder vil bli godkjent av en admin før du kan se
            det på nettsiden.
          </p>
          <button
            className="p-2 my-4 w-full text-center text-s leading-5 font-semibold rounded-full bg-blue-200 text-blue-800"
            type="button"
            onClick={() => firebase.auth().signInAnonymously()}
          >
            Jeg forstår, lås opp skjema
          </button>
        </>
      )}
      <Form
        onSubmit={onSubmit}
        initialValues={{ url: 'https://', createdBy: currentUser }}
        render={({
          handleSubmit,
          submitting,
          submitError,
          submitSucceeded,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div>
                <Field
                  name="name"
                  label="Navn på brukersted"
                  placeholder="Eksempelbutikken"
                  disabled={!currentUser}
                  component={TextField}
                />

                <Field
                  component={TextField}
                  disabled={!currentUser}
                  label="Nettside"
                  name="url"
                  pattern="https://.*"
                  placeholder="https://example.com"
                  type="url"
                />

                <Field
                  label=" Lokasjon (nettbutikk, la stå blank)"
                  placeholder="F.eks Oslo, stavanger, trondheim"
                  name="info"
                  disabled={!currentUser}
                  component={TextField}
                />
                <Field
                  autocomplete="off"
                  component="input"
                  disabled={!currentUser}
                  name="honey"
                  style={{ display: `none` }}
                  type="checkbox"
                  value="1"
                />
                <div className="my-4 ">
                  <label className="mt-2 text-gray-600 dark:text-gray-400">
                    Beskrivelse
                  </label>
                  <Field
                    disabled={!currentUser}
                    className="flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    cols="30"
                    component="textarea"
                    name="description"
                    placeholder="En kort beskrivelse for dette brukerstedet. F.eks `sportsbutikk`"
                    required
                    rows="5"
                    type="text"
                  />
                </div>

                <fieldset className="my-4 ">
                  <legend className="flex justify-start items-start mt-2 text-gray-600 dark:text-gray-400">
                    Velg en eller flere kategorier
                  </legend>
                  {currentUser &&
                    categories?.map(({ id, name }) => (
                      <label
                        className=" flex justify-start items-start mt-2 text-gray-600 dark:text-gray-400"
                        htmlFor={name}
                      >
                        <Field
                          id={name}
                          key={id}
                          name="categories"
                          type="checkbox"
                          validate={required}
                          value={id}
                          render={({ input, meta }) => (
                            <>
                              <div className="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                                <input
                                  {...input}
                                  type="checkbox"
                                  className="opacity-0 absolute"
                                />
                                <svg
                                  className="fill-current hidden w-4 h-4 text-green-500 pointer-events-none"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                                </svg>
                              </div>
                              <div className="select-none">{name}</div>
                            </>
                          )}
                        />
                      </label>
                    ))}
                </fieldset>
                <SubmitError submitError={submitError} />
                <SubmitSucceed submitSucceeded={submitSucceeded} />
              </div>
              {currentUser && (
                <button
                  className="p-2 my-4 w-full text-center text-s leading-5 font-semibold rounded-full bg-blue-200 text-blue-800"
                  disabled={submitting}
                  type="submit"
                >
                  Send inn brukersted til godkjenning
                </button>
              )}
            </form>
          );
        }}
      />

      {/* <button onClick={() => firebase.auth().signOut()}>logutt</button> */}
    </div>
  );
};
