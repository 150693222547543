import React, { useState } from 'react';
import Hamburger from 'hamburger-react';
import { Menu } from '../Menu/Menu';
import firebase from '../../firebaseconfig';
import { Link } from 'react-router-dom';

export const Navigation = () => {
  const [isOpen, setOpen] = useState(false);

  const onClose = (cat: string | null) => {
    if (null) return setOpen(false);
    firebase.analytics().logEvent(`view_category_${cat}`);
    setOpen(!isOpen);
  };

  return (
    <nav className="bg-white dark:bg-gray-800 shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 my-4">
          <Link
            className="text-2xl font-bold text-gray-800 dark:text-white lg:text-3xl hover:text-gray-700 dark:hover:text-gray-300"
            to="/"
          >
            AmexVenn
          </Link>
          <div className="flex">
            <p
              className="
                bg-blue-200
                dark:hover:bg-gray-700
                dark:hover:text-gray-200
                flex 
                hover:bg-blue-100 
                hover:text-blue-900 
                items-center
                justify-center 
                lg:px-20 
                px-6
                py-0
                m-1
                rounded-full 
                text-blue-800
                text-sm 
                transition duration-300 ease-in-out cursor-pointer
                "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="h-5 w-5 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
              <Link to="/search">Søk</Link>
            </p>
            <div className="relative inline-block text-left">
              <Hamburger
                color="#fd4d4d"
                size={18}
                toggled={isOpen}
                toggle={() => setOpen(!isOpen)}
              />
              <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5">
                {isOpen && <Menu onClose={onClose} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
