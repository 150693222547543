import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import firebase from './firebaseconfig';
import { FuegoProvider } from '@nandorojo/swr-firestore';
import { Fuego } from './fuego';
import { Navigation } from './components/Header/Navigation';
import { AddStorePage } from './Pages/AddStore';
import { StorePage } from './Pages/Store';
import { Home } from './Pages/Home';
import { Category } from './Pages/Category';
import { SearchPage } from './Pages/Search';
// import { Scripts } from './Pages/Scripts';

import './styles/main.css';
import './App.css';

firebase.analytics();
const fuego = new Fuego(firebase);

function App() {
  return (
    <Router>
      <FuegoProvider fuego={fuego}>
        <div className="App">
          <Navigation />
          <main>
            <Switch>
              <Route path="/search" component={SearchPage} />
              <Route component={Category} path="/category/:categoryName" />
              <Route path="/new" render={() => <AddStorePage />} />
              <Route path="/userplace/:storeName" component={StorePage} />
              {/* <Route path="/scripts" component={Scripts} /> */}

              <Route component={Home} path="/" />
            </Switch>
          </main>
        </div>
      </FuegoProvider>
    </Router>
  );
}

export default App;
