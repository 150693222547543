import React from 'react';
import { Link } from 'react-router-dom';
import { UseGetCategories } from '../../hooks';

interface IProps {
  onClose: (cat: string | null) => void;
}

export const Menu = ({ onClose }: IProps) => {
  const categories = UseGetCategories();

  return (
    <aside
      className="fixed inset-y-0 right-0 bg-white dark:bg-gray-800 h-full w-full max-w-md z-50"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      <div className="py-6 px-8">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-700 dark:text-white">
            Kategorier
          </h2>
          <button onClick={() => onClose(null)} className="hover:text-red-700">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#fd4d4d"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        {categories?.map((cat) => (
          <Link
            className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-600 mb-4 text-xl"
            role="menuitem"
            onClick={() => onClose(cat?.name)}
            key={cat.id}
            to={`/category/${cat.name}`}
          >
            {cat.name.charAt(0).toUpperCase() + cat.name.slice(1)}
          </Link>
        ))}
      </div>
    </aside>
  );
};
